import React from "react";
import { DateInputIcon } from "../common/Icons";

const DateInput = ({ text, selectedDate, handleInputChange, name, trip }) => {
  return (
    <>
      <div className={`w-full flex flex-col gap-2 overflow-hidden`}>
        <p className="font-poppins text-base leading-4 font-medium text-light_blue">
          {text}
        </p>
        <div className="flex relative items-center gap-4 border border-blue h-[40.70px] overflow-hidden">
          <input
            type="date"
            onChange={handleInputChange}
            placeholder="Select Date"
            value={selectedDate}
            name={name}
            className="w-full font-poppins pl-[38px] h-full font-medium text-base leading-4 placeholder:text-blue text-light_blue outline-none"
          />

          <div className="absolute bottom-[8px] left-[8px]">
            <DateInputIcon />
          </div>
        </div>
      </div>
    </>
  );
};

export default DateInput;
