import React, { useEffect } from "react";
import ClassDropdown from "./ClassDropdown";
import PessangerDropdown from "./PessangerDropdown";
import TriptypeDropdown from "./TripTypeDropdown";

const FirstTwoDropdowns = ({
  CLASS_OPTIONS,
  TRIP_OPTIONS,
  setSeat,
  seat,
  setTrip,
  trip,
  formdata,
  setFormdata,
  counts,
  setCounts,
}) => {
  useEffect(() => {
    setFormdata({ ...formdata, tripValue: trip, classValue: seat });
  }, [seat, trip]);
  return (
    <>
      <div className="grid grid-cols-1 w-full sm:flex sm:gap-2 gap-1 flex-wrap">
        <TriptypeDropdown
          trip={trip}
          setTrip={setTrip}
          TRIP_OPTIONS={TRIP_OPTIONS}
          setSeat={setSeat}
          seat={seat}
          CLASS_OPTIONS={CLASS_OPTIONS}
        />
        <ClassDropdown
          setSeat={setSeat}
          seat={seat}
          CLASS_OPTIONS={CLASS_OPTIONS}
        />
        <PessangerDropdown
          counts={counts}
          setCounts={setCounts}
          formdata={formdata}
          setFormdata={setFormdata}
        />
      </div>
    </>
  );
};

export default FirstTwoDropdowns;
