import React, { useState } from "react";
import CommonButton from "../common/CommonButton";
import { CheckIcon } from "../common/Icons";

const PersonalInfo = ({ formdata, setFormdata }) => {
  const [checked, setChecked] = useState(false);

  return (
    <>
      <div className="flex flex-col md:gap-[30px] gap-[26px] mb-[30px]">
        <p className="font-josefin w-fit font-semibold md:text-3xl text-[28px] md:leading-md leading-9 text-light_blue relative underline decoration-[1px] underline-offset-4">
          Personal Info
        </p>
        <div className="w-full flex flex-col justify-between lg:gap-[30px] gap-5">
          <div className="flex justify-between flex-wrap lg:gap-[30px] gap-5">
            <div className="flex flex-wrap w-full lg:gap-7 gap-5">
              <div className="lg:w-[48%] md:w-[47.5%] w-full">
                <p className="font-poppins text-base leading-4 font-medium text-light_blue sm:mb-4 mb-3">
                  First Name
                </p>
                <div className="flex items-center gap-4 border border-blue py-[10px] px-[15px] relative">
                  <input
                    required
                    onChange={(e) =>
                      setFormdata({ ...formdata, firstName: e.target.value })
                    }
                    type="text"
                    placeholder="Enter First Name..."
                    className="w-full font-poppins font-medium text-base leading-4 placeholder:text-blue text-light_blue outline-none"
                    value={formdata.firstName}
                  />
                </div>
              </div>
              <div className="lg:w-[48%] md:w-[47.5%] w-full">
                <p className="font-poppins text-base leading-4 font-medium text-light_blue sm:mb-4 mb-3">
                  Last Name
                </p>
                <div className="flex items-center gap-4 border border-blue py-[10px] px-[15px] relative">
                  <input
                    required
                    onChange={(e) =>
                      setFormdata({ ...formdata, lastName: e.target.value })
                    }
                    type="text"
                    placeholder="Enter Last Name..."
                    className="w-full font-poppins font-medium text-base leading-4 placeholder:text-blue text-light_blue outline-none"
                    value={formdata.lastName}
                  />
                </div>
              </div>
              <div className="lg:w-[48%] md:w-[47.5%] w-full">
                <p className="font-poppins text-base leading-4 font-medium text-light_blue sm:mb-4 mb-3">
                  Email
                </p>
                <div className="flex items-center gap-4 border border-blue py-[10px] px-[15px] relative">
                  <input
                    required
                    onChange={(e) =>
                      setFormdata({ ...formdata, email: e.target.value })
                    }
                    type="email"
                    placeholder="Enter Email..."
                    className="w-full font-poppins font-medium text-base leading-4 placeholder:text-blue text-light_blue outline-none"
                    value={formdata.email}
                  />
                </div>
              </div>
              <div className="lg:w-[48%] md:w-[47.5%] w-full">
                <p className="font-poppins text-base leading-4 font-medium text-light_blue sm:mb-4 mb-3">
                  Phone
                </p>
                <div className="flex items-center gap-4 border border-blue py-[10px] px-[15px] relative">
                  <input
                    required
                    onChange={(e) =>
                      setFormdata({ ...formdata, phoneNumber: e.target.value })
                    }
                    type="number"
                    placeholder="Enter Phone..."
                    className="w-full font-poppins font-medium text-base leading-4 placeholder:text-blue text-light_blue outline-none"
                    value={formdata.phoneNumber}
                  />
                </div>
              </div>
            </div>
            <div className="w-full">
              <p className="font-poppins text-base leading-4 font-medium text-light_blue mb-[15px]">
                Message
              </p>
              <textarea
                onChange={(e) =>
                  setFormdata({ ...formdata, message: e.target.value })
                }
                value={formdata.message}
                placeholder="Write a message..."
                className="w-full resize-none h-[88px] py-3 px-[15px] border border-blue font-poppins font-medium text-base sm:leading-4 leading-5 placeholder:text-blue text-light_blue outline-none"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center relative justify-between">
        <input
          type="checkbox"
          checked={checked}
          className="opacity-0 top-[50%] left-0 absolute"
          id="robot"
          required
          name="robot"
        />
        <label htmlFor="robot" className="flex items-center gap-[15px]">
          <span
            className="w-5 h-5 border-[1.5px] border-gray rounded-md flex items-center justify-center cursor-pointer"
            onClick={() => setChecked(!checked)}
          >
            {checked && <CheckIcon />}
          </span>
          <p
            className="font-poppins font-normal sm:text-lg text-[16px] leading-[30px] text-gray cursor-pointer"
            onClick={() => setChecked(!checked)}
          >
            I am not a robot
          </p>
        </label>
        <CommonButton
          text="Send"
          type="submit"
          className="xl:!py-5 2xl:!py-6 !border-transparent !bg-orange hover:!border-orange hover:!bg-white hover:!text-orange"
        />
      </div>
    </>
  );
};

export default PersonalInfo;
