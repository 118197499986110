import ContactForm from "../contact-us/ContactForm";
import ContactHero from "../contact-us/ContactHero";
import Showcase from "../contact-us/Showcase";
const ContactUs = () => {
  return (
    <div className="overflow-hidden">
      <ContactHero />
      <ContactForm />
      <Showcase />
    </div>
  );
};

export default ContactUs;
