import emailjs, { init } from "@emailjs/browser";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import logo from "../../assets/images/svg/page-logo.svg";
import reverseFrame from "../../assets/images/webp/solution-frame-2.webp";
import frame from "../../assets/images/webp/solution-frame.webp";
import PrimaryHeading from "../common/PrimaryHeading";
import {
  carRentalContent,
  childAge,
  hotelBookingContent,
  infantAge,
  multiCityContent,
  oneWayContent,
  roundTripContent,
} from "../utils";
import CarRentalInfo from "./CarRentalInfo";
import data from "./data.json";
import FirstTwoDropdowns from "./FirstTwoDropdowns";
import HotelTripInfo from "./HotelTripInfo";
import PersonalInfo from "./PersonalInfo";
import TravelTabs from "./TravelTabs";
import TripInfo from "./TripInfo";
const TRIP_OPTIONS = ["Round trip", "One way", "Multi city"];
const CLASS_OPTIONS = ["Economy", "Premium Economy", "Business", "First Class"];

const ContactForm = () => {
  const tabs = ["Flight", "Hotel", "Car Rental"];
  const [trip, setTrip] = useState(TRIP_OPTIONS[0]);
  const [show, setShow] = useState(false);
  const [seat, setSeat] = useState(CLASS_OPTIONS[0]);
  const [carRent, setCarRent] = useState("Different Drop-off");
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [rooms, setRooms] = useState(1);
  const [counts, setCounts] = useState({
    Children: 0,
    Adult: 1,
    Infants: 0,
  });

  useEffect(() => {
    setCounts({
      Children: 0,
      Adult: 1,
      Infants: 0,
    });
  }, [activeTab]);

  const initialValue = {
    tripValue: "Round trip",
    classValue: "Economy",
    multicity: [
      { from: "", toValue: "", departDate: "" }, // Initial leg
    ],
    travelers: 1,
    childernAge: [],
    infantsAge: [],
    from: "",
    toValue: "",
    departDate: "",
    returndate: "",
    setLocation: "",
    checkInDate: "",
    checkOutDate: "",

    pickupLocation: "",
    dropOffLocation: "",
    pickUpDate: "",
    dropOffDate: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    message: "",
  };
  const [formdata, setFormdata] = useState(initialValue);
  init("fyFv-ZDd8E_vTTOoS");
  const SERVICE_ID = "service_vufsiuk";
  const TEMPLATE_ID = "template_k5gwhar";
  const onSubmitHandler = (e) => {
    e.preventDefault();

    let detailsMsg;

    let totalPessanger = counts.Children + counts.Adult + counts.Infants;
    let pessengerDetails = `Children: ${counts.Children}\nAdult: ${counts.Adult}\nInfants: ${counts.Infants}`;
    let childrenAge = "";
    let infantsAge = "";
    if (formdata.childernAge.length > 0) {
      childrenAge = childAge(formdata);
    }
    if (formdata.infantsAge.length > 0) {
      infantsAge = infantAge(formdata);
    }
    if (activeTab == "Flight") {
      if (trip == "Round trip") {
        detailsMsg = roundTripContent(
          formdata,
          totalPessanger,
          pessengerDetails,
          childrenAge,
          infantsAge
        );
      }

      if (trip == "One way") {
        detailsMsg = oneWayContent(
          formdata,
          totalPessanger,
          pessengerDetails,
          childrenAge,
          infantsAge
        );
      }

      if (trip == "Multi city") {
        detailsMsg = multiCityContent(
          formdata,
          totalPessanger,
          pessengerDetails,
          childrenAge,
          infantsAge
        );
      }
    }
    if (activeTab == "Hotel") {
      detailsMsg = hotelBookingContent(
        formdata,
        rooms,
        counts,
        childrenAge,
        infantsAge
      );
    }
    if (activeTab == "Car Rental") {
      detailsMsg = carRentalContent(carRent, formdata);
    }
    data = {
      flightDetails: detailsMsg,
      firstName: formdata.firstName,
      lastName: formdata.lastName,
      phoneNumber: formdata.phoneNumber,
      email: formdata.email,
      message: formdata.message,
    };

    emailjs.send(SERVICE_ID, TEMPLATE_ID, data).then((result) => {
      toast("submitted successfully!");
      setFormdata(initialValue);
    });
  };

  return (
    <div className="2xl:max-w-[1920px] mx-auto xl:pb-[120px] md:pb-20 pb-16">
      <div className="container max-w-[1800px] px-5">
        <div className="relative w-full xl:max-w-[1166px] mx-auto">
          <div className="max-w-[825px]  2xl:py-14 2xl:px-[60px] sm:p-6 p-3 md:p-14 border border-blue mx-auto bg-white -mt-[75px] sm:-mt-[110px] flex flex-col 2xl:gap-[60px] sm:gap-[50px] gap-10">
            <div className="flex justify-between gap-8">
              <PrimaryHeading>
                <span className="common_heading">Quote</span>
              </PrimaryHeading>

              <div className="md:w-[273px] md:h-[80px] sm:w-[220px] sm:h-[60px] w-[175px] h-[50px]">
                <img src={logo} alt="logo" className="w-full" />
              </div>
            </div>
            <form onSubmit={onSubmitHandler}>
              {/* Travel Info section */}
              <div className="flex flex-col md:gap-[30px] gap-[26px] md:mb-[60px] mb-[50px]">
                <p className="font-josefin w-fit font-semibold md:text-3xl text-[28px] md:leading-md leading-9 text-light_blue relative underline decoration-[1px] underline-offset-4">
                  Travel Info
                </p>
                <TravelTabs
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  tabs={tabs}
                />
                {/* =========================================DROPDOWNS========================================== */}

                {activeTab === "Flight" && (
                  <>
                    <FirstTwoDropdowns
                      show={show}
                      setShow={setShow}
                      TRIP_OPTIONS={TRIP_OPTIONS}
                      CLASS_OPTIONS={CLASS_OPTIONS}
                      trip={trip}
                      setTrip={setTrip}
                      seat={seat}
                      setSeat={setSeat}
                      formdata={formdata}
                      setFormdata={setFormdata}
                      counts={counts}
                      setCounts={setCounts}
                    />
                    <TripInfo
                      trip={trip}
                      setFormdata={setFormdata}
                      formdata={formdata}
                    />
                  </>
                )}
                {activeTab === "Hotel" && (
                  <HotelTripInfo
                    formdata={formdata}
                    setFormdata={setFormdata}
                    counts={counts}
                    setCounts={setCounts}
                    rooms={rooms}
                    setRooms={setRooms}
                  />
                )}
                {activeTab === "Car Rental" && (
                  <CarRentalInfo
                    formdata={formdata}
                    setFormdata={setFormdata}
                    carRent={carRent}
                    setCarRent={setCarRent}
                  />
                )}
              </div>

              <PersonalInfo formdata={formdata} setFormdata={setFormdata} />
            </form>
          </div>
          <img
            src={frame}
            alt="frame"
            className="w-full max-w-[522px] pointer-events-none hidden lg:block absolute top-[230px] xl:left-0 -left-4 -z-10"
          />
          <img
            src={reverseFrame}
            alt="frame"
            className="w-full max-w-[522px] pointer-events-none hidden lg:block absolute top-[230px] xl:right-0 -right-4 -z-10"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
