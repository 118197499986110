import React, { useEffect, useState } from "react";
import axios from "axios";

const PlaceAutocomplete = ({ setFormdata, formdata, objKey }) => {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const handleInputChange = async (e) => {
    const input = e.target.value;
    setQuery(input);

    if (input.length > 2) {
      try {
        const response = await axios.get(
          `https://api.opencagedata.com/geocode/v1/json?q=${input}&key=51b44b5d93d04225ae5bf543539fab4b&limit=5`
        );
        setSuggestions(response.data.results.map((result) => result.formatted));
      } catch (error) {
        console.error("Error fetching data from OpenCage API:", error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    setSuggestions([]); // Clear suggestions after selection
  };

  useEffect(() => {
    setFormdata({ ...formdata, [objKey]: query });
  }, [query]);
  return (
    <div className="autocomplete-container bg-white relative z-10">
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        required
        placeholder="Enter location Name..."
        className="autocomplete-input font-medium !text-[15px] !text-light_blue !pl-[38px]"
      />
      {suggestions.length > 0 && (
        <ul className="borde absolute top-full left-0 rounded mt-2 p-2 bg-white shadow-lg max-h-48 overflow-y-auto">
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
              className="cursor-pointer hover:bg-gray-200 p-2"
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PlaceAutocomplete;
