import React from "react";
import { DateInputIcon, LocationIcon } from "../common/Icons";
import CarRentType from "./CarRentType";
import PlaceAutocomplete from "./PlaceAutocomplete";

const CarRentalInfo = ({ formdata, setFormdata, carRent, setCarRent }) => {
  return (
    <>
      <CarRentType carRent={carRent} setCarRent={setCarRent} />
      <div
        className={`grid  gap-4 ${
          carRent != "Pick up Location"
            ? "grid-cols-1 md:grid-cols-2"
            : "grid-cols-1"
        }`}
      >
        <div className="flex flex-col gap-2">
          <p className="font-poppins text-base leading-4 font-medium text-light_blue">
            Pick up Location
          </p>
          <div className="autocomplete relative">
            <PlaceAutocomplete
              setFormdata={setFormdata}
              objKey="pickupLocation"
              formdata={formdata}
            />
            <div className="absolute z-20 bottom-[8px] left-[8px]">
              <LocationIcon />
            </div>
          </div>
        </div>

        {carRent != "Pick up Location" && (
          <div className="flex flex-col gap-2">
            <p className="font-poppins text-base leading-4 font-medium text-light_blue">
              Drop off Location
            </p>
            <div className="autocomplete relative">
              <PlaceAutocomplete
                setFormdata={setFormdata}
                objKey="dropOffDate"
                formdata={formdata}
              />
              <div className="absolute z-20 bottom-[8px] left-[8px]">
                <LocationIcon />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className={`w-full flex flex-col gap-2 overflow-hidden`}>
          <p className="font-poppins text-base leading-4 font-medium text-light_blue">
            Pick up Date
          </p>
          <div className="flex relative items-center gap-4 border border-blue h-[40.70px] overflow-hidden">
            <input
              type="date"
              placeholder="Select Date"
              value={formdata.pickUpDate}
              onChange={(e) =>
                setFormdata({ ...formdata, pickUpDate: e.target.value })
              }
              className="w-full font-poppins pl-[38px] h-full font-medium text-base leading-4 placeholder:text-blue text-light_blue outline-none"
            />

            <div className="absolute bottom-[8px] left-[8px]">
              <DateInputIcon />
            </div>
          </div>
        </div>
        <div className={`w-full flex flex-col gap-2 overflow-hidden`}>
          <p className="font-poppins text-base leading-4 font-medium text-light_blue">
            Drop off Date
          </p>
          <div className="flex relative items-center gap-4 border border-blue h-[40.70px] overflow-hidden">
            <input
              type="date"
              placeholder="Select Date"
              value={formdata.dropOffDate}
              onChange={(e) =>
                setFormdata({ ...formdata, dropOffDate: e.target.value })
              }
              className="w-full font-poppins pl-[38px] h-full font-medium text-base leading-4 placeholder:text-blue text-light_blue outline-none"
            />

            <div className="absolute bottom-[8px] left-[8px]">
              <DateInputIcon />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CarRentalInfo;
