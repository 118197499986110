import React from "react";
import { DateInputIcon, LocationIcon } from "../common/Icons";
import LocationInputWrapper from "./LocationInputWrapper";
import HotalRoomSelect from "./HotalRoomSelect";
import PessangerDropdown from "./PessangerDropdown";
import PlaceAutocomplete from "./PlaceAutocomplete";

const HotelTripInfo = ({
  formdata,
  setFormdata,
  counts,
  setCounts,
  rooms,
  setRooms,
}) => {
  return (
    <>
      <div className="flex gap-3">
        <HotalRoomSelect rooms={rooms} setRooms={setRooms} />{" "}
        <PessangerDropdown
          formdata={formdata}
          setFormdata={setFormdata}
          counts={counts}
          setCounts={setCounts}
        />
      </div>
      <div className="space-y-4">
        <div className="flex flex-col gap-2">
          <p className="font-poppins text-base leading-4 font-medium text-light_blue">
            Set Location
          </p>
          <div className="autocomplete relative">
            <PlaceAutocomplete
              setFormdata={setFormdata}
              objKey="setLocation"
              formdata={formdata}
            />
            <div className="absolute z-20 bottom-[8px] left-[8px]">
              <LocationIcon />
            </div>
          </div>
          {/* <LocationInputWrapper /> */}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className={`w-full flex flex-col gap-2 overflow-hidden`}>
            <p className="font-poppins text-base leading-4 font-medium text-light_blue">
              Check in Date
            </p>
            <div className="flex relative items-center gap-4 border border-blue h-[40.70px] overflow-hidden">
              <input
                type="date"
                placeholder="Select Date"
                value={formdata.checkInDate}
                onChange={(e) =>
                  setFormdata({ ...formdata, checkInDate: e.target.value })
                }
                className="w-full font-poppins pl-[38px] h-full font-medium text-base leading-4 placeholder:text-blue text-light_blue outline-none"
              />

              <div className="absolute bottom-[8px] left-[8px]">
                <DateInputIcon />
              </div>
            </div>
          </div>
          <div className={`w-full flex flex-col gap-2 overflow-hidden`}>
            <p className="font-poppins text-base leading-4 font-medium text-light_blue">
              Check out Date
            </p>
            <div className="flex relative items-center gap-4 border border-blue h-[40.70px] overflow-hidden">
              <input
                type="date"
                placeholder="Select Date"
                value={formdata.checkOutDate}
                onChange={(e) =>
                  setFormdata({ ...formdata, checkOutDate: e.target.value })
                }
                className="w-full font-poppins pl-[38px] h-full font-medium text-base leading-4 placeholder:text-blue text-light_blue outline-none"
              />

              <div className="absolute bottom-[8px] left-[8px]">
                <DateInputIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HotelTripInfo;
