import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import React, { useEffect, useState } from "react";

export default function PessangerDropdown({
  formdata,
  setFormdata,
  counts,
  setCounts,
}) {
  const data = [
    {
      option: "Adult",
      age: "(12y +)",
    },
    {
      option: "Children",
      age: "(2y - 12y )",
    },

    {
      option: "Infants",
      age: "(Below 2y)",
    },
  ];

  const [childrenAge, setChildrenAge] = useState([]);
  const [infantsAge, setInfantsAge] = useState([]);
  // Pessenger Increment Function
  const incrementCount = (option) => {
    if (option == "Adult" && counts.Adult === 9) return;
    if (option == "Children" && counts.Children === 6) return;
    if (option == "Infants" && counts.Infants === 6) return;
    if (option == "Infants" && counts.Adult === counts.Infants) {
      return alert("Number of infants cannot be more than adults");
    }

    setCounts((prevCounts) => ({
      ...prevCounts,
      [option]: prevCounts[option] + 1,
    }));
    if (option == "Children") {
      setChildrenAge((pre) => {
        const newChildrenAge = { age: 8 };
        return [...pre, newChildrenAge];
      });
    }
    if (option == "Infants") {
      setInfantsAge((pre) => {
        const newInfantsAge = { age: "Below 1" };
        return [...pre, newInfantsAge];
      });
    }
  };
  // Pessenger Decrement Function
  const decrementCount = (option) => {
    if (option == "Adult" && counts.Adult === 1) return;
    if (option == "Adult" && counts.Adult === counts.Infants) {
      return alert("Number of infants cannot be more than adults");
    }
    setCounts((prevCounts) => ({
      ...prevCounts,
      [option]: prevCounts[option] > 0 ? prevCounts[option] - 1 : 0,
    }));
    if (option == "Children") {
      setChildrenAge((prevChildrenAge) => {
        const newChildrenAge = [...prevChildrenAge];
        newChildrenAge.pop();
        return newChildrenAge;
      });
    }

    if (option == "Infants") {
      setInfantsAge((prevInfantsAge) => {
        const newInfantsAge = [...prevInfantsAge];
        newInfantsAge.pop();
        return newInfantsAge;
      });
    }
  };
  // Children Age Handler
  const childrenAgeChange = (e, index) => {
    const updatedAge = Number(e.target.value);
    setChildrenAge((prevChildrenAge) => {
      const newChildrenAge = [...prevChildrenAge];
      newChildrenAge[index] = { age: updatedAge };
      return newChildrenAge;
    });
  };
  // Infant Age Handler
  const infantsAgeChange = (e, index) => {
    const updatedAge = e.target.value;
    console.log(e.target.value);
    setInfantsAge((prevInfantsAge) => {
      const newInfantsAge = [...prevInfantsAge];
      newInfantsAge[index] = { age: updatedAge };
      console.log(newInfantsAge);
      return newInfantsAge;
    });
  };
  // Age Update In Formdata
  useEffect(() => {
    setFormdata({
      ...formdata,
      childernAge: childrenAge,
      infantsAge: infantsAge,
    });
  }, [childrenAge, infantsAge]);
  return (
    <div className="w-full sm:w-[133px] relative">
      <Menu>
        <MenuButton className="cursor-pointer w-full justify-between max-w-full min-w-full border border-[#41546b6f] shadow-md rounded-lg px-2 py-2 flex items-center text-[#41546B] text-[16px] font-medium ff_poppins">
          <span>
            {" "}
            Traveler ({counts.Adult + counts.Children + counts.Infants}){" "}
          </span>
          <ChevronDownIcon className="size-6 fill-[#41546B]" />
        </MenuButton>

        <MenuItems
          transition
          anchor="bottom end"
          className="w-[250px] mt-1 origin-top-right rounded-xl border shadow-lg border-[#41546b6f] bg-[#fff] p-1 text-sm/6 text-[#41546B] transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
        >
          <MenuItem disabled>
            <ul className="py-1">
              {data.map((option, i) => (
                <li
                  className="cursor-pointer py-2  px-3 flex justify-between items-center"
                  key={i}
                >
                  <span className="min-w-[80px] flex gap-1 items-center">
                    <span className="leading-[1] text-base">
                      {" "}
                      {option.option}'s
                    </span>
                    <span className="text-[12px] leading-[1]">
                      {" "}
                      {option.age}{" "}
                    </span>{" "}
                  </span>
                  <div className="flex items-center">
                    <button
                      type="button"
                      className="w-[25px] text-white h-[25px] bg-orange"
                      onClick={(e) => {
                        e.stopPropagation();
                        decrementCount(option.option);
                      }}
                    >
                      -
                    </button>
                    <span className="px-2">{counts[option.option]}</span>
                    <button
                      type="button"
                      className="w-[25px] text-white h-[25px] bg-orange"
                      onClick={(e) => {
                        e.stopPropagation();
                        incrementCount(option.option);
                      }}
                    >
                      +
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </MenuItem>
          {counts.Children > 0 && (
            <>
              <div className="w-full h-[1px] bg-[#41546b6f]"></div>
              <MenuItem
                disabled
                as="ul"
                className={"flex flex-col gap-2 px-2 py-2"}
              >
                <div data-open className="flex flex-col gap-2">
                  <div className="flex text-sm"> Select Age of children: </div>
                  <div className="flex flex-col gap-1">
                    {Array.from({ length: counts.Children }).map(
                      (child, index) => (
                        <div key={index} className="flex items-center gap-2">
                          <span className="text-sm whitespace-nowrap font-semibold">
                            {" "}
                            Child {index + 1}
                          </span>
                          <select
                            className="cursor-pointer justify-between rounded-md px-2 w-full border py-1 border-[#41546b4f] shadow-md flex items-center text-[#41546B] text-[14px] font-medium ff_poppins"
                            name="cars"
                            id="cars"
                            onChange={(e) => childrenAgeChange(e, index)}
                            value={
                              childrenAge.length > 0
                                ? childrenAge[index].age
                                : ""
                            }
                          >
                            {Array.from({ length: 11 }).map((age, index) => (
                              <option key={index} value={index + 2}>
                                {index + 2}
                              </option>
                            ))}
                          </select>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </MenuItem>
            </>
          )}

          {counts.Infants > 0 && (
            <>
              <div className="w-full h-[1px] bg-[#41546b6f]"></div>
              <MenuItem
                disabled
                as="ul"
                className={"flex flex-col gap-2 px-2 py-2"}
              >
                <div data-open className="flex flex-col gap-2">
                  <div className="flex text-sm"> Select Age of Infants: </div>
                  <div className="flex flex-col gap-1">
                    {Array.from({ length: counts.Infants }).map(
                      (child, index) => (
                        <div key={index} className="flex items-center gap-2">
                          <span className="text-sm whitespace-nowrap font-semibold">
                            {" "}
                            Infant {index + 1}
                          </span>
                          <select
                            className="cursor-pointer justify-between rounded-md px-2 w-full border py-1 border-[#41546b4f] shadow-md flex items-center text-[#41546B] text-[14px] font-medium ff_poppins"
                            name="cars"
                            id="cars"
                            onChange={(e) => infantsAgeChange(e, index)}
                          >
                            <option value="Below 1">Below 1</option>
                            <option value="1"> 1</option>
                          </select>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </MenuItem>
            </>
          )}
        </MenuItems>
      </Menu>
    </div>
  );
}
