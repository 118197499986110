import HappyTravelers from "../homepage/HappyTravelers";
import Hero from "../homepage/Hero";
import OurPartners from "../homepage/OurPartners";
import ServicesOffer from "../homepage/ServicesOffer";
import TravelSolutions from "../homepage/TravelSolutions";

const Home = () => {
  return (
    <div className="overflow-hidden">
      <Hero />
      <TravelSolutions />
      <ServicesOffer />
      <OurPartners />
      <HappyTravelers />
    </div>
  );
};

export default Home;
