import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import React from "react";
const TriptypeDropdown = ({ TRIP_OPTIONS, trip, setTrip }) => {
  return (
    <>
      <div className="w-full sm:w-[120px] relative">
        <Menu>
          <MenuButton className="cursor-pointer justify-between w-full border border-[#41546b6f] shadow-md rounded-lg px-2 py-2 flex items-center text-[#41546B] text-[16px] font-medium ff_poppins">
            <span> {trip} </span>
            <ChevronDownIcon className="size-6 fill-[#41546B]" />
          </MenuButton>

          <MenuItems
            transition
            anchor="bottom end"
            className="w-[150px] mt-1 origin-top-right rounded-xl border shadow-lg border-[#41546b6f] bg-[#fff] p-1 text-sm/6 text-[#41546B] transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
          >
            <MenuItem>
              <ul className="py-1 px-2">
                {TRIP_OPTIONS.map((obj, i) => (
                  <li
                    key={i}
                    className="py-1"
                    onClick={() => {
                      setTrip(obj);
                    }}
                  >
                    <input
                      checked={trip === obj}
                      type="radio"
                      name="trip"
                      id={obj}
                      className="cursor-pointer"
                    />
                    <label className="ps-2 cursor-pointer" htmlFor={obj}>
                      {obj}
                    </label>
                  </li>
                ))}
              </ul>
            </MenuItem>
          </MenuItems>
        </Menu>
      </div>
    </>
  );
};

export default TriptypeDropdown;
