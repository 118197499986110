import React, { useState, useCallback } from "react";
import { FromInputIcon, ToInputIcon } from "./Icons";

const AutoCompleteSearch = ({
  options,
  inputValue,
  setInputValue,
  objectKey,
  keyLabel,
  formdata,
}) => {
  const [filteredOptions, setFilteredOptions] = useState([]);

  const [showOptions, setShowOptions] = useState(false);

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const handleFilter = (value) => {
    if (value.length > 0) {
      const filtered = options.filter(
        (option) =>
          option.name.toLowerCase().includes(value.toLowerCase()) ||
          option.city.toLowerCase().includes(value.toLowerCase()) ||
          option.country.toLowerCase().includes(value.toLowerCase()) ||
          option.iata.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredOptions(filtered);
      setShowOptions(true);
    } else {
      setShowOptions(false);
    }
  };

  const debouncedFilter = useCallback(debounce(handleFilter, 300), [options]);

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(() => ({ ...formdata, [objectKey]: e.target.value }));
    debouncedFilter(value);
  };

  const handleOptionClick = (option) => {
    setInputValue(`${option.name} (${option.iata})`);
    setInputValue(() => ({
      ...formdata,
      [objectKey]: `${option.name} (${option.iata})`,
    }));
    setShowOptions(false);
  };

  return (
    <div className="flex flex-col gap-2">
      <p className="font-poppins text-base leading-4 font-medium text-light_blue">
        {keyLabel}
      </p>
      <div className="autocomplete relative">
        <input
          type="text"
          value={inputValue}
          onChange={handleChange}
          required
          placeholder="Search by airport..."
          className="autocomplete-input font-medium !text-[15px] !text-light_blue !pl-[38px]"
        />
        <div className="absolute bottom-[8px] left-[8px]">
          {objectKey == "from" && <FromInputIcon />}
          {objectKey == "toValue" && <ToInputIcon />}
        </div>

        {showOptions && filteredOptions.length > 0 && (
          <div className="autocomplete-items">
            {filteredOptions.map((option, index) => (
              <div
                key={index}
                onClick={() => handleOptionClick(option)}
                className={`option`}
              >
                {option.name} ({option.city}, {option.country}) [{option.iata}]
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AutoCompleteSearch;
