import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import React, { useState } from "react";
const CarRentType = ({ carRent, setCarRent }) => {
  const [rideType, setRideType] = useState([
    "Pick up Location",
    "Different Drop-off",
  ]);
  return (
    <>
      <div className="w-full sm:w-[190px] relative">
        <Menu>
          <MenuButton className="cursor-pointer justify-between w-full border border-[#41546b6f] shadow-md rounded-lg px-2 py-2 flex items-center text-[#41546B] text-[16px] font-medium ff_poppins">
            <span> {carRent} </span>
            <ChevronDownIcon className="size-6 fill-[#41546B]" />
          </MenuButton>

          <MenuItems
            transition
            anchor="bottom end"
            className="w-[180px] mt-1 origin-top-right rounded-xl border shadow-lg border-[#41546b6f] bg-[#fff] p-1 text-sm/6 text-[#41546B] transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
          >
            <MenuItem>
              <ul className="py-1 px-2">
                {rideType.map((data, i) => (
                  <li key={i} className="py-1" onClick={() => setCarRent(data)}>
                    <input
                      checked={carRent == data}
                      type="radio"
                      name="trip"
                      id={i + "ride-type"}
                      className="cursor-pointer"
                    />
                    <label
                      className="ps-2 cursor-pointer"
                      htmlFor={i + "ride-type"}
                    >
                      {data}
                    </label>
                  </li>
                ))}
              </ul>
            </MenuItem>
          </MenuItems>
        </Menu>
      </div>
    </>
  );
};

export default CarRentType;
