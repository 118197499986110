export const roundTripContent = (
  formdata,
  totalPessanger,
  pessengerDetails,
  childrenAge,
  infantsAge
) => {
  return `Dear Admin,\nPlease find the flight details for Round trip as follows:\nFrom: ${formdata.from}\nTo: ${formdata.toValue}\nDepart Date: ${formdata.departDate}\nReturn Date: ${formdata.returndate}\nEconomy: ${formdata.classValue}\nTotal Passengers: ${totalPessanger}\n${pessengerDetails}\n\n${childrenAge}\n${infantsAge}`;
};

export const oneWayContent = (
  formdata,
  totalPessanger,
  pessengerDetails,
  childrenAge,
  infantsAge
) => {
  return `Dear Admin,\nPlease find the details for the one way trip as follows:\nFrom: ${formdata.from}\nTo: ${formdata.toValue}\nDepart Date: ${formdata.departDate}\nEconomy: ${formdata.classValue}\nTotal Passengers: ${totalPessanger}\n${pessengerDetails}\n\n${childrenAge}\n${infantsAge}`;
};

export const multiCityContent = (
  formdata,
  totalPessanger,
  pessengerDetails,
  childrenAge,
  infantsAge
) => {
  return `Dear Admin,\nPlease find the details for the multi-city trip as follows:\n${formdata.multicity
    .map(
      (leg, index) =>
        `Leg ${index + 1}:\nFrom: ${leg.from}\nTo: ${
          leg.toValue
        }\nDepart Date: ${leg.departDate}\nEconomy: ${
          formdata.classValue
        }\n\nTotal Passengers: ${totalPessanger}\n`
    )
    .join("\n")}${pessengerDetails}\n\n${childrenAge}\n${infantsAge}`;
};

export const childAge = (formdata) => {
  return `Children's ages:-
      ${formdata.childernAge
        .map(
          (child, i) => `${i == 0 ? "\n" : ""}Child ${i + 1}: ${child.age} Yrs`
        )
        .join("\n")}
      `;
};

export const infantAge = (formdata) => {
  return `Infant's ages:-
      ${formdata.infantsAge
        .map(
          (infant, i) =>
            `${i == 0 ? "\n" : ""}Infant ${i + 1}: ${infant.age} Yr`
        )
        .join("\n")}
      `;
};

export const hotelBookingContent = (
  formdata,
  rooms,
  counts,
  childrenAge,
  infantsAge
) => {
  return `
Hotel Booking Details:\n
Location: ${formdata.setLocation}
Check-In Date: ${formdata.checkInDate}
Check-Out Date: ${formdata.checkOutDate}
Rooms: ${rooms}\n
Guests:- \n\n${counts.Adult} Adults\n${counts.Children} Children\n${counts.Infants} Infants\n\n${childrenAge}\n${infantsAge}`;
};

export const carRentalContent = (carRent, formdata) => {
  return `
Car Rental Details:
Ride Type: ${carRent}
Pick up Location: ${formdata.pickupLocation || "N/A"}
Drop off Location: ${formdata.dropOffLocation || "N/A"}
Pick up Date: ${formdata.pickUpDate || "N/A"}
Drop off Date: ${formdata.dropOffDate || "N/A"}
`;
};
