import React from "react";

const CommonButton = ({ text, className }) => {
  return (
    <button
      className={`${className} font-semibold lg:text-2xl text-xl leading-6 max-md:h-[50px] max-lg:h-[60px] h-[72px] text-white md:py-6 py-4 md:px-8 px-5 lg:px-12 border border-white duration-300 ease-linear hover:bg-white hover:text-gray bg-transparent flex items-center justify-center`}
    >
      {text}
    </button>
  );
};

export default CommonButton;
