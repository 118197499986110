import { useEffect, useState } from "react";
import { MARQUE_ITEMS } from "../common/Helper";
import AirplaneImg from "../../assets/images/webp/airplane-image.webp";
import LogoAirplane from "../../assets/images/png/logo-airplane.png";
import PrimaryHeading from "../common/PrimaryHeading";
import CommonParagraph from "../common/CommonParagraph";

const OurPartners = () => {
  const [isAnimationPaused, setIsAnimationPaused] = useState(false);
  const [shuffledSliders, setShuffledSliders] = useState([]);

  useEffect(() => {
    const HANDLE_KEY_PRESS = (event) => {
      if (event.code === "Space") {
        setIsAnimationPaused((prev) => !prev);
      }
    };
    document.addEventListener("keydown", HANDLE_KEY_PRESS);

    const shuffledAndDistributed = shuffleAndDistributeItems(MARQUE_ITEMS);
    setShuffledSliders(shuffledAndDistributed);

    return () => {
      document.removeEventListener("keydown", HANDLE_KEY_PRESS);
    };
  }, []);

  const SHUFFLE_ARRAYS = (array) => {
    const shuffled = [...array, ...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const shuffleAndDistributeItems = (items) => {
    const shuffled = SHUFFLE_ARRAYS(items);
    const totalItems = shuffled.length;
    const itemsPerSlider = Math.ceil(totalItems / 3);

    return [
      shuffled.slice(0, itemsPerSlider),
      shuffled.slice(itemsPerSlider, itemsPerSlider * 2),
      shuffled.slice(itemsPerSlider * 2),
    ];
  };

  const RENDER_MARQUEE = (items, isReverse = false) => (
    <div
      className={`enable-animation ${isReverse ? "reverse-animation" : ""} ${
        isAnimationPaused ? "paused" : ""
      }`}
    >
      <div className="marquee 2xl:gap-[90px] lg:gap-14 md:gap-8 gap-0">
        <div className="marquee_content">
          {items.map((obj, index) => (
            <div className="marquee_item" key={index}>
              <img
                src={obj.marImg}
                alt="company-images"
                className="max-sm:!w-[150px] max-sm:!h-[60px]"
              />
            </div>
          ))}
        </div>
        <div className="marquee_content" aria-hidden="true">
          {items.map((obj, index) => (
            <div className="marquee_item" key={`duplicate-${index}`}>
              <img
                src={obj.marImg}
                alt="company-images"
                className="max-sm:!w-[150px] max-sm:!h-[60px]"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="max-w-[1920px] mx-auto pt-16 md:pt-14 xl:pt-[120px]">
      <PrimaryHeading className="text-center 2xl:mb-5 mb-3">
        Our&nbsp;
        <span className="common_heading">Partners</span>
      </PrimaryHeading>
      <div className="flex items-center justify-center px-5 2xl:mb-[90px] lg:mb-20 md:mb-[70px] mb-[60px]">
        <CommonParagraph
          text="At Clever Travel Solutions, we proudly partner with leading airlines, luxury hotels, and exclusive resorts worldwide to provide you with unparalleled travel experiences."
          className="max-w-[954px] text-center"
        />
      </div>
      {shuffledSliders[0] && RENDER_MARQUEE(shuffledSliders[0])}
      <div className="lg:my-12 md:my-8 my-5">
        {shuffledSliders[1] && RENDER_MARQUEE(shuffledSliders[1], true)}
      </div>
      <div className="lg:my-12 md:my-8 my-5">
        {shuffledSliders[2] && RENDER_MARQUEE(shuffledSliders[2])}
      </div>
      <div className="max-w-[1800px] mx-auto px-5 xl:mt-[120px] md:mt-[80px] mt-16 xl:h-[818px] md:h-[600px] sm:h-[400px] h-[350px] relative">
        <div className="absolute md:bottom-[80px] sm:bottom-10 bottom-6 md:right-[80px] sm:right-10 right-6 md:w-[273px] w-[170px] md:h-[80px] h-[50px] cursor-pointer">
          <img
            src={LogoAirplane}
            alt="project-logo"
            className="w-full h-full pointer-events-none"
          />
        </div>
        <img
          src={AirplaneImg}
          alt="airplane-image"
          className="w-full h-full pointer-events-none"
        />
      </div>
    </div>
  );
};

export default OurPartners;
