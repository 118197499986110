import React from "react";

const TravelTabs = ({ activeTab, setActiveTab, tabs }) => {
  return (
    <div className="w-full bg-[#41546B] bg-opacity-10 p-1 sm:p-1.5">
      <div className="grid grid-cols-3 gap-1 sm:gap-1.5">
        {tabs.map((tab) => (
          <button
            key={tab}
            type="button"
            onClick={() => setActiveTab(tab)}
            className={`flex-1 py-2 w-full text-[14px] sm:text-[24px] text-center font-semibold transition-colors duration-300 ${
              activeTab === tab ? "bg-orange text-white" : "bg-white text-gray"
            }`}
          >
            {tab}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TravelTabs;
