import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import showCaseImg from "../../assets/images/contact/png/showcase-site-img-1.png";
import showCaseImgTwo from "../../assets/images/contact/png/showcase-site-img-2.png";
import showCaseImgThird from "../../assets/images/contact/png/showcase-site-img-3.png";
import showCaseImgForth from "../../assets/images/contact/png/showcase-site-img-4.png";
import showCaseImgFive from "../../assets/images/contact/png/showcase-site-img-5.png";
import PrimaryHeading from "../common/PrimaryHeading";
import CommonParagraph from "../common/CommonParagraph";

const Showcase = () => {
  return (
    <div className="max-w-[1840px] mx-auto px-5">
      <PrimaryHeading className="text-center">
        <span className="common_heading">Showcase</span>&nbsp;
        <span className="max-sm:block">of Excellence</span>
      </PrimaryHeading>
      <CommonParagraph
        text="View our curated collection of travel highlights and see how we turn dream destinations into reality."
        className="text-center mt-5 xl:mb-14 lg:mb-8 mb-6"
      />
      <Swiper
        slidesPerView={4}
        spaceBetween={20}
        loop={true}
        loopFillGroupWithBlank={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper relative max-4xl:!px-0"
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
      >
        <SwiperSlide>
          <div className="px-2">
            <img src={showCaseImg} alt="places to visit" className="w-full" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="px-2">
            <img
              src={showCaseImgTwo}
              alt="places to visit"
              className="w-full"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="px-2">
            <img
              src={showCaseImgThird}
              alt="places to visit"
              className="w-full"
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="px-2">
            <img
              src={showCaseImgForth}
              alt="places to visit"
              className="w-full"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="w-full min-h-full">
          <div className="px-2 h-full">
            <img
              src={showCaseImgFive}
              alt="places to visit"
              className="w-full h-full object-cover"
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Showcase;
