import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/views/Home";
import ContactUs from "./components/views/ContactUs";
import Header from "./components/common/Header";
import GetInTouch from "./components/common/GetInTouch";
import { ToastContainer } from "react-toastify";
import { WhatsApp } from "./components/common/Icons";

function App() {
  return (
    <>
      <div className="fixed right-[3%] sm:bottom-[5%] bottom-[3%] z-[100]">
        <a
          target="_blank"
          className="transition-all duration-300 ease-in-out hover:scale-[1.1] inline-block"
          href="https://wa.me/3473856647?text=Hello%2C%20I%27m%20interested%20in%20your%20services%21"
        >
          <WhatsApp />
        </a>
      </div>
      <BrowserRouter>
        <Header /> <ToastContainer position="bottom-right" />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
        </Routes>
      </BrowserRouter>
      <GetInTouch />
    </>
  );
}

export default App;
